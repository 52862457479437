@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

$purple: #590696;
$light-purple: #8648d5;
$downy: #62c6c5;
$white: #fff;
$red: #e02424;
$medium-gray: #d0d5dd;
$gray: #667085;

@layer components {
  .bg-purple-ios {
    background-color: #590696 !important;
    opacity: 1 !important;
  }

  body {
    @apply text-gray-900;
  }

  .container {
    @apply max-w-[1290px] mx-auto px-4 md:px-0;
  }

  .heading-1 {
    @apply text-3xl md:text-[36px] md:leading-[40px] font-semibold;
  }

  label {
    font-weight: 500 !important;
    color: #101828 !important;
  }

  input[type="file"]::file-selector-button {
    @apply bg-downy-300 hover:bg-purple-900 focus:ring-0  rounded-l-[100px];
  }

  .jumbotron {
    // should be relative path of the entry scss file
    background-image: url("../../vendors/images/sample.jpg");
    background-size: cover;
  }

  .btn-blue {
    @apply inline-flex items-center;
    @apply px-4 py-2;
    @apply font-semibold rounded-lg shadow-md;
    @apply text-white bg-blue-500;
    @apply hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
  }

  .btn-primary::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  .btn-primary.white::before {
    background: #fff;
  }

  .btn-primary.purple::before {
    @apply bg-purple-900;
  }

  .btn-primary.downy::before {
    @apply bg-downy-300;
  }

  .btn-primary {
    overflow: hidden;
    transform: perspective(1px) translateZ(0);
    transition-property: color;
    transition-duration: 0.3s;
  }

  .btn-primary:hover::before {
    transform: scale(1);
  }

  .btn-cookie {
    @apply cursor-pointer text-center underline underline-offset-2 sm:no-underline;
    @apply font-semibold text-sm px-1.5 sm:px-5 sm:py-2 rounded-[100px];
  }

  .advantage-card {
    position: relative;
  }

  .advantage-card .front,
  .advantage-card .back {
    position: absolute;
    backface-visibility: hidden;
    width: 100%;
    height: 100%;
  }

  @keyframes slide-in-top {
    0% {
      transform: translateY(-1000px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .slide-in-top {
    animation: slide-in-top 2s ease-in-out both;
  }

  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled {
    opacity: 1 !important;
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 40px !important;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 14px !important;
    line-height: 40px !important;
  }

  .custom-shadow {
    filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 40%));
  }

  .get-started-btn:hover .circle {
    @apply border-gold;
  }

  .get-started-btn:hover .icon {
    @apply text-rose-600;
  }

  .sidebar ul li.active a {
    @apply relative text-purple-900;
  }

  .sidebar ul li.active a::before {
    content: "";
    position: absolute;
    top: 0;
    left: -4px;
    width: 11px;
    height: 100%;
    background-color: $purple;
    border-radius: 4px;
    border: 4px solid $white;

    @media screen and (max-width: 1024px) {
      content: none;
    }
  }

  label[for^="id_categories_"],
  label[for^="id_reasons_"] {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    line-height: 24px;
  }

  label + label {
    margin-top: 1em;
  }

  .custom-form-fields label {
    @apply text-sm;
  }

  .custom-form-fields input[type="password"] {
    background-color: #fff;
    padding: 14px 20px;
    border-radius: 100px;
    font-size: 14px;
    line-height: 20px;
    border-color: $medium-gray;
  }

  .custom-form-fields input[type="password"]:focus {
    border-color: $downy;
    box-shadow: 0 0 2px $downy;
  }

  input[type="checkbox"] {
    appearance: none;
    background-color: $white;
    margin: 0;
    font: inherit;
    color: $white;
    width: 16px;
    height: 16px;
    border: 1px solid $medium-gray;
    border-radius: 4px;
    display: grid;
    place-content: center;
  }

  input[type="checkbox"]:checked {
    appearance: none;
    background-color: $downy;
    margin: 0;
    font: inherit;
    color: $downy;
    width: 16px;
    height: 16px;
    border: 1px solid $downy;
    border-radius: 4px;
    display: grid;
    place-content: center;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 10px;
    height: 10px;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    background-color: $white;
  }

  input[type="checkbox"]:focus {
    outline: max(2px, 2px) solid $downy;
    outline-offset: max(2px, 2px);
    box-shadow: none;
  }

  .checkbox-delete div {
    @apply inline-flex items-center ;
  }

  .checkbox-delete div label {
    @apply order-2 mb-0;
  }

  .checkbox-delete div input {
    @apply order-1 mr-2;
  }

  .checkbox-delete div input[type="checkbox"]:checked {
    @apply mr-2 bg-red-600 text-red-600 border-red-600;
  }

  .checkbox-delete div input[type="checkbox"]:focus {
    outline: max(2px, 2px) solid $red;
  }

  .category-wrap > div > div > div {
    @apply grid grid-cols-1 md:grid-cols-2 gap-2;
  }

  .reasons-wrap > div > div > div > div {
    @apply mb-3;
  }

  .crispy-form .alert > div {
    @apply border-0 bg-transparent text-red-600;
  }

  .list-alphabetic li {
    @apply flex gap-2;
  }

  .list-alphabetic li div:first-child {
    @apply shrink-0;
  }

  .crispy-form .alert > div > ul > li {
    @apply pl-6 relative;
  }

  .crispy-form .alert > div > ul > li::before {
    content: url("../../vendors/images/icon-info.svg");
    position: absolute;
    top: 2.5px;
    left: 0;
  }

  p[id^="error_"] {
    @apply mt-1;
  }

  p[id^="error_"] strong {
    @apply text-xs font-medium text-red-600 not-italic;
  }

  select {
    border-radius: 100px !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    z-index: 20;
    position: relative;
  }

  form select {
    @apply focus:ring-downy-300 focus:border-downy-300;
  }

  input[type="file"] {
    @apply block w-full text-sm text-gray-900 border border-gray-300 rounded-[100px] bg-gray-50 focus:outline-none mb-2;
  }

  .custom-tagsinput .tagsinput {
    width: 100% !important;
    height: auto !important;
    padding: 9px 9px 4px;
    border-radius: 8px;
    margin-bottom: 16px;
  }

  .custom-tagsinput .ui-autocomplete-input:focus {
    outline: none;
  }

  .custom-tagsinput .ui-autocomplete-input::placeholder {
    color: $gray;
    font-size: 14px;
  }

  .custom-tagsinput .tagsinput .tag {
    color: $light-purple;
    border-radius: 8px;
    background-color: #f9f6fe;
    padding: 4px 30px 4px 10px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
  }

  .custom-tagsinput .tagsinput .tag a::after,
  .custom-tagsinput .tagsinput .tag a::before {
    background-color: $light-purple;
  }

  .custom-tagsinput .flex-container label {
    font-size: 14px;
    font-weight: 500 !important;
    display: block;
    margin-bottom: 16px;
  }

  .public,
  .dispute,
  .dialog {
    @apply bg-orange-200;
  }

  .progress {
    @apply bg-emerald-200;
  }

  .todo {
    @apply bg-indigo-200;
  }

  .new,
  span[class*="_proposal"] {
    @apply bg-cyan-200;
  }

  .proposal,
  .starting {
    @apply bg-purple-200;
  }

  .complete,
  .done {
    @apply bg-yellow-200;
  }

  .draft {
    @apply bg-gray-200;
  }

  .rejected {
    @apply bg-red-200;
  }

  .canceled {
    @apply bg-red-200;
  }

  .success {
    @apply bg-emerald-100 text-emerald-600;
  }

  .pending {
    @apply bg-yellow-100 text-yellow-600;
  }

  .failed {
    @apply bg-red-100 text-red-600;
  }

  .my-content {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--read-more-line-clamp, 2);
  }

  .divider-y .formset-divider {
    @apply border-t border-gray-300 py-4;
  }

  .divider-y .new-form {
    @apply border-t border-gray-300 mb-4;
  }

  .form-milestones .divider-y .new-form {
    @apply border-none;
  }

  .divider-y .new-form .formset-divider:nth-child(even) {
    @apply border-none mt-0;
  }

  .divider-y .new-form .checkbox-delete {
    @apply hidden;
  }

  .scrollbar-w-2::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
  }

  .scrollbar-track-blue-lighter::-webkit-scrollbar-track {
    @apply bg-gray-50;
  }

  .scrollbar-thumb-blue::-webkit-scrollbar-thumb {
    @apply bg-gray-200;
  }

  .scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
    @apply rounded;
  }

  #chat .message-own .message-wrap {
    @apply justify-end;
  }

  #chat .message-own .avatar {
    @apply hidden;
  }

  #chat .message-own .message-body {
    @apply bg-downy-200;
  }

  #chat .message-recieved .message-body {
    @apply bg-downy-100;
  }

  #chat .message-system .message-wrap {
    @apply justify-center;
  }

  #chat .message-system .message-wrap > div {
    @apply max-w-[500px] w-full;
  }

  #chat .message-system .message-body {
    @apply p-0;
  }

  .show {
    display: block;
  }

  .remove {
    display: none;
  }

  .form-error {
    @apply text-red-600 mb-3 font-medium;
  }

  input[type="radio"] {
    appearance: none;
    display: inline-block;
    width: 16px;
    height: 16px;
    padding: 4px;
    background-clip: content-box;
    border: 1px solid #d4d4d8;
    border-radius: 50%;
    background-color: #f3f4f6;
    color: transparent;
  }

  input[type="radio"]:checked,
  input[type="radio"]:focus {
    background-color: transparent;
    background-image: none;
    width: 16px;
    height: 16px;
    padding: 2px;
    border: 5px solid #62c6c5 !important;
    box-shadow: none;
  }

  .list-item-wrap {
    @apply w-full sm:w-[calc(50%-16px)] border border-downy-300 bg-white rounded-lg px-4 py-2 mt-3 relative;
  }

  .file-delete {
    @apply absolute top-2 right-4 cursor-pointer;
  }

  .my-content.long {
    -webkit-line-clamp: var(--read-more-line-clamp, 4);
  }

  div[modal-backdrop] {
    @apply z-[101];
  }
}
